
import React from "react";
import { FaDiscord } from 'react-icons/fa';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/Navbar.js";


export default function Index() {
  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");

  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("register-page");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  }, []);

  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)"
    );
    setSquares7and8(
      "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    );
  };
  return (
    <>
      
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-2 offset-md-2" lg="8" md="8">
                  <div
                    className="square square-7"
                    id="square7"
                    style={{ transform: squares7and8 }}
                  />
                  <div
                    className="square square-8"
                    id="square8"
                    style={{ transform: squares7and8 }}
                  />
              
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-orange-logo.png")}
                      />
                      <CardTitle tag="h4">&nbsp;</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <p className="text-white"><center>
                    We are preparing for our beta launch and will be available soon. <br />
                    For the latest information, follow us on Twitter or join us in the Discord!
                    </center></p> 
                    </CardBody>
                    <CardFooter>
                      <Row>
                      <Col className="offset-lg-3 offset-md-2 offset-sm-2" lg="3" md="4" sm="4" xs="6"><center>
                       <a 
                             href="https://twitter.com/archwayfun"
                              rel="noopener noreferrer"
                              target="_blank"
                              title="Follow us on Twitter"
                            >
                             
                          <Button className="btn-round" color="primary" size="sm"style={{
                              padding: "10px 20px"
                            
                              }} >
                       
                             <i className="fab fa-twitter" style={{
                              "font-size": "1.55em"
                              }}
                              />
                        
                          </Button>
                          </a></center>
                      </Col>
                       <Col className="offset-lg-0 offset-md-0 " lg="3" md="4" sm="4" xs="6"><center>
                      <a 
                        href="https://discord.com/invite/nP4jgbr3Tv"
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Join us on Discord"
                      >
                      <Button className="btn-round" color="primary" size="sm" style={{
                              padding: "10px 20px",
                              }} >
                        <FaDiscord style={{
                              "font-size": "1.6em"
                              }}
                              />
                        
                      </Button>
                      </a></center>
                      </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
                <Col>
                <div
                className="square square-1"
                id="square1"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-2"
                id="square2"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-3"
                id="square3"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-4"
                id="square4"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-5"
                id="square5"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-6"
                id="square6"
                style={{ transform: squares1to6 }}
              /></Col>
           </Row>
            </Container>
          </div>
        </div>
    
      </div>
    </>
  );
}
